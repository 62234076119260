import React from "react";
import { Card, Button } from "react-bootstrap";

const Calendar = () => {
  const events = [
    { date: "Dec 12, 2024", event: "The International Mathematics Olympiad (SOF IMO)" },
    { date: "Dec 12, 2024", event: "International Mathematics Olympiad" },
    { date: "Dec 4, 2024", event: "Spell Bee for classes 3-9" },
  ];

  return (
    <div>
      <h4 className="text-danger">School Calendar</h4>
      {events.map((item, index) => (
        <Card key={index} className="mb-2">
          <Card.Body>
            <small className="text-muted">{item.date}</small>
            <p>{item.event}</p>
          </Card.Body>
        </Card>
      ))}
      <Button variant="link" className="p-0">
        View More &rarr;
      </Button>
    </div>
  );
};

export default Calendar;
