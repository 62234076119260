import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';


function Requirement() {
    return (
        <div className="container mt-2">
            <div className="text-center mb-4">
                <h1
                    className="mb-4"
                    style={{ fontSize: 'clamp(2rem, 5vw, 3rem)' }} // Dynamically adjusts font size
                >
                    Requirement Page
                </h1>

                <div className="d-flex align-items-center justify-content-center mb-4">
                    <hr style={{ flex: 1, borderTop: '2px solid #000' }} />
                    <span style={{ padding: '0 10px' }}>NATION FIRST PUBLIC SCHOOL</span>
                    <hr style={{ flex: 1, borderTop: '2px solid #000' }} />
                </div>
            </div>

            <div className="container mt-4">
                {/* Use Bootstrap's grid system */}
                <div className="row gy-3">
                    <div className="col-md-6">
                        <FloatingLabel controlId="floatingInput" label="Your Name" className="flex-fill">
                            <Form.Control type="text" placeholder="Your Name" />
                        </FloatingLabel>
                    </div>

                    <div className="col-md-6">
                        <FloatingLabel controlId="floatingContactNumber" label="Your Contact Number" className="flex-fill">
                            <Form.Control
                                type="tel"
                                placeholder="Your Contact Number"
                                pattern="[0-9]*" // Optional: restrict to numbers
                            />
                        </FloatingLabel>
                    </div>
                </div>
            </div>

            <div className="container mt-4">
                <FloatingLabel controlId="floatingEmail" label="Email ID" className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder="Email ID"
                        required // Optional: Adds form validation for email input
                    />
                </FloatingLabel>
            </div>

            <div className="container mt-4">
                <div className="mb-3">
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className="form-label">Upload CV</Form.Label>
                        <Form.Control type="file" />
                    </Form.Group>
                </div>
            </div>

            <div className="container mt-4">
                <FloatingLabel controlId="floatingPostOfApplication" label="Post of Application" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Post of Application"
                        required
                    />
                </FloatingLabel>
            </div>

            <div className="container mt-4">
                <FloatingLabel controlId="floatingSubject" label="Subject" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Subject"
                        required // Makes the field mandatory
                    />
                </FloatingLabel>
            </div>

            <div className="container mt-4">
                <FloatingLabel controlId="floatingMessage" label="Drop a Message" className="mb-3">
                    <Form.Control
                        as="textarea"
                        placeholder="Drop a Message if you want"
                        style={{ height: '100px' }} // Adjust the height as needed
                        required
                    />
                </FloatingLabel>
            </div>

            <div className="col-12 pt-3">
                <button
                    className="btn btn w-100 py-3"
                    style={{
                        color: "white",
                        backgroundColor: "#498455"
                    }}
                    type="submit"
                    value="Send"
                >
                    SUBMIT YOUR QUOTE
                </button>
            </div>

        </div>
    );
}

export default Requirement;