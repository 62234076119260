// src/AdmissionForm.js
import { React, useState} from "react";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const FormAdm = () => {
  console.log("Rerendered...");

  const [formData, setFormData] = useState({
    childPhoto: null,
    fatherPhoto: null,
    motherPhoto: null,
    class: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    bloodGroup: "",
    nationality: "",
    caste: "",
    casteCertificate: null,
    religion: "",
    fatherName: "",
    motherName: "",
    guardianOccupation: "",
    language: [],
    residentialAddress: "",
    correspondenceAddress: "",
    contactnumber: "",
  });

  const [submissions, setSubmissions] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "file") {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    } else if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        language: checked
          ? [...prevData.language, value]
          : prevData.language.filter((lang) => lang !== value),
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ["childPhoto", "class", "firstName", "fatherName"];
  for (const field of requiredFields) {
    if (!formData[field]) {
      alert(`Please fill in the required field: ${field}`);
      return;
    }
  }

    // Check for duplicate in the table data
    const isDuplicate = submissions.some(
      (row) =>
        row.firstName === formData.firstName &&
        row.fatherName === formData.fatherName
    );

    if (isDuplicate) {
      console.log("in is Duplicate check");
      alert("data already exist..");
    } else {
      // setErrors(null);
      if (isEditing) {
        console.log("in is isEditing");
        const updatedSubmissions = [...submissions];
        updatedSubmissions[editIndex] = formData;
        setSubmissions(updatedSubmissions);
        setIsEditing(false);
        setEditIndex(null);
      } else {
        console.log("in Adding new content");
        try {
          // Backend endpoint
            const backendUrl = "http://localhost:8080/admissions/add";
  
          // Prepare the payload
          const payload = { ...formData, language: formData.language.join(", ") };
  
          // Send the form data to the backend
          const response = await fetch(backendUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
          });
  
          if (response.ok) {
              const result = await response.json();
              alert(result.message);
              console.log("Submission successful:", result);
  
              // Reset form after successful submission
              setFormData({
                  childPhoto: null,
                  fatherPhoto: null,
                  motherPhoto: null,
                  class: "",
                  firstName: "",
                  lastName: "",
                  dob: "",
                  gender: "",
                  bloodGroup: "",
                  nationality: "",
                  caste: "",
                  casteCertificate: null,
                  religion: "",
                  fatherName: "",
                  motherName: "",
                  guardianOccupation: "",
                  language: [],
                  residentialAddress: "",
                  correspondenceAddress: "",
                  contactnumber: "",
              });
          } else {
              alert("Submission failed. Please try again.");
              console.error("Error in submission:", await response.text());
          }
      } catch (error) {
          console.error("Error in submission:", error);
          alert("An error occurred during submission. Please try again.");
      }
      }
    }
  };

  const handleDelete = (index) => {
    const newSubmissions = submissions.filter((_, i) => i !== index);
    setSubmissions(newSubmissions);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredSubmissions = submissions.filter((submission) => {
    return Object.entries(submission).some(([key, value]) => 
      typeof value === "string" && value.toLowerCase().includes(searchTerm)
    );
  });

  const handleEdit = (index) => {
    setFormData(filteredSubmissions[index]);
    setIsEditing(true);
    setEditIndex(index);
  };

  return (
    <Container fluid="md" className="p-4">
      <h2 className="text-center">NATION FIRST PUBLIC SCHOOL</h2>
      <p className="text-center">2023-2024 Online Admission Form</p>
      <Form onSubmit={handleSubmit}>
        {/* Upload Section */}
        <Row className="text-center">
          <Col md={4}>
            <Form.Label>
              Child Photo<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              name="childPhoto"
              key={formData.childPhoto || ""} // Use key to reset input
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={4}>
            <Form.Label>Father Photo</Form.Label>
            <Form.Control
              type="file"
              name="fatherPhoto"
              key={formData.fatherPhoto || ""} // Use key to reset input
              onChange={handleChange}
            />
          </Col>
          <Col md={4}>
            <Form.Label>Mother Photo</Form.Label>
            <Form.Control
              type="file"
              name="motherPhoto"
              key={formData.motherPhoto || ""} // Use key to reset input
              onChange={handleChange}
            />
          </Col>
        </Row>

        {/* Student Details */}
        <Row className="mt-4">
          <Col md={4}>
            <Form.Group>
              <Form.Label>
                Class<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="class"
                value={formData.class}
                onChange={handleChange}
                required
              >
                <option>Select Class</option>
                <option>1</option>
                <option>2</option>
                {/* Add more options as needed */}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>
                First Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>
                Last Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Additional Fields */}
        <Row className="mt-4">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option>Select Gender</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Blood Group</Form.Label>
              <Form.Control
                as="select"
                name="bloodGroup"
                value={formData.bloodGroup}
                onChange={handleChange}
              >
                <option>Select Blood Group</option>
                <option>A+</option>
                <option>B+</option>
                {/* Add more options as needed */}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Nationality</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Nationality here"
                name="nationality"
                value={formData.nationality}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Caste</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Caste here"
                name="caste"
                value={formData.caste}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Label>Caste Certificate</Form.Label>
            <Form.Control
              type="file"
              name="casteCertificate"
              value={formData.casteCertificate}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Religion</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Religion here"
                name="religion"
                value={formData.religion}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>
                Father's Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Father's Name Over here"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Label>Mother's Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Mother's Name Over here"
              name="motherName"
              value={formData.motherName}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Guarian's Occupation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Guardian's Occupation here"
                name="guardianOccupation"
                value={formData.guardianOccupation}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label className="mb-3">Language Known</Form.Label>
              <div className="mb-3">
                <Form.Check
                  inline
                  label="Bengali"
                  type="checkbox"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label="English"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                  type="checkbox"
                />
                <Form.Check
                  inline
                  label="Hindi"
                  type="checkbox"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Entercontact number here.."
              name="caste"
              value={formData.contactnumber}
              onChange={handleChange}
            />
          </Col>
        </Row>

        {/* Contact Information */}
        <Row className="mt-4">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Residential Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Residential Address"
                name="caste"
                value={formData.residentialAddress}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Correspondence Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Correspondence Address"
                name="caste"
                value={formData.correspondenceAddress}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Submission */}
        <Row className="text-center mt-4">
          <Col>
            <Button variant="secondary" type="reset">
              Reset Form
            </Button>{" "}
            <Button variant="primary" type="submit">
              {isEditing ? "Update Admission Form" : "Submit Admission Form"}
            </Button>
          </Col>
        </Row>
      </Form>

      {/* Display Table */}
      <h3 className="text-center mt-5">Submitted Admissions</h3>
      <div className="col-md-6">
        <Form.Control
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <Table striped bordered hover className="mt-2">
        <thead className="text-center">
          <tr>
            <th>Student Name</th>
            <th>Student's Photo</th>
            <th>Class</th>
            <th>Guardian's Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {filteredSubmissions.map((submission, index) => (
            <tr key={index}>
              <td>
                {submission.firstName} {submission.lastName}
              </td>
              <td>
                {submission.childPhoto ? (
                  <img
                    src={URL.createObjectURL(submission.childPhoto)}
                    alt="Child"
                    width="70"
                  />
                ) : (
                  "N/A"
                )}
              </td>
              <td>{submission.class}</td>
              <td>{submission.fatherName}</td>
              <td>
                <Button variant="danger" onClick={() => handleDelete(index)}>
                  Delete
                </Button>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(index)}
                  style={{ margin: "10px" }}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default FormAdm;
