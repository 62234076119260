import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Services() {
    const services = [
        { title: 'Skilled Instructors', description: 'Details about skilled instructors' },
        { title: 'Online Classes', description: 'Details about online classes' },
        { title: 'Home Projects', description: 'Details about home projects' },
        { title: 'Book Library', description: 'Details about book library' }
    ];

    return (
        <Container className="my-5 text-center">
            <Row>
                {services.map((service, index) => (
                    <Col key={index} md={3}>
                        <Card className="shadow-sm mb-3">
                            <Card.Body>
                                <Card.Title>{service.title}</Card.Title>
                                <Card.Text>{service.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Services;
