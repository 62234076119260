import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import s1Img from '../assets/s1.jpg';
import s2Img from '../assets/s2.jpg';
import s3Img from '../assets/s3.jpg';



function HeroSection() {
    return (
        <Container fluid className="p-0 m-0">
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={s1Img} // replace with actual image path
                        alt="Slide 1"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={s2Img} // replace with actual image path
                        alt="Slide 2"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={s3Img} // replace with actual image path
                        alt="Slide 3"
                    />
                </Carousel.Item>
            </Carousel>
        </Container>
    );
}

export default HeroSection;
