import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
    return (
        <Container fluid className="bg-dark text-white py-5">
            <Row>
                <Col md={4}>
                    <h5>School Hours</h5>
                    <p>Monday - Friday: 8am - 2:40pm</p>
                    <p>Saturday & Sunday: Closed</p>
                </Col>
                <Col md={4}>
                    <h5>Quick Links</h5>
                    <p>Home | About | Notice Board</p>
                </Col>
                <Col md={4}>
                    <h5>Contact</h5>
                    <p>Ganima Road, Kolkata</p>
                    <p>Email: nationfirstpublicschool2020@gmail.com</p>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
