import React from 'react';
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/school.png';
import './frontstyle.css';



function SchoolNavbar() {
    return (
        <Navbar expand="lg" bg="white" variant="light" className="shadow-sm">
            <Container>
                <Row className="w-100">
                    {/* Logo and Brand Section */}
                    <Col xs={12} lg={2} className="d-flex align-items-center">
                        <LinkContainer to="/">
                            <Navbar.Brand style={{ fontFamily: '"Nunito Sans", sans-serif' }}>
                                <img src={logo} alt="Logo" height="50" className="me-2" />
                                {/* <span className="fw-bold text-primary">
                                    NATION FIRST <br /> PUBLIC SCHOOL
                                </span> */}
                            </Navbar.Brand>
                        </LinkContainer>
                    </Col>

                    {/* Navbar Toggle (Hamburger icon on mobile) */}
                    <Col xs={6} lg={6} className="d-flex justify-content-end d-lg-none">
                        <Navbar.Toggle aria-controls="navbarNav" />
                    </Col>

                    {/* Navbar Links Section */}
                    <Col xs={12} lg={10} className="d-flex justify-content-end">
                        <Navbar.Collapse id="navbarNav" style={{ fontFamily: '"Nunito Sans", sans-serif' }}>
                            <Nav className="ms-auto">
                                <LinkContainer to="/home">
                                    <Nav.Link className="text-dark fw-bold">HOME</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/about">
                                    <Nav.Link className="text-dark fw-bold">ABOUT</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/notice-board">
                                    <Nav.Link className="text-dark fw-bold">NOTICE BOARD</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/gallery">
                                    <Nav.Link className="text-dark fw-bold">GALLERY</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/requirement">
                                    <Nav.Link className="text-dark fw-bold">RECRUITMENT</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/contact">
                                    <Nav.Link className="text-dark fw-bold">CONTACT</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/admin-login">
                                    <Nav.Link className="text-dark fw-bold me-3">ADMIN LOGIN</Nav.Link>
                                </LinkContainer>

                            </Nav>

                            {/* Admission Button */}
                            <LinkContainer to="/admission">
                                <Button
                                    className="btn btn-primary py-3 px-5 fw-bold"
                                    style={{ fontFamily: '"Nunito Sans", sans-serif' }}
                                >
                                    Admission Open ➔
                                </Button>

                            </LinkContainer>
                        </Navbar.Collapse>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
}

export default SchoolNavbar;
