import { FaChalkboardTeacher, FaBookOpen, FaCalendarAlt, FaGraduationCap } from 'react-icons/fa';

// const ShortcutLinks = () => {
//   const links = [
//     { icon: <FaCalendarAlt />, label: 'Admissions', url: '/admissions' },
//     { icon: <FaBookOpen />, label: 'Library', url: '/library' },
//     { icon: <FaChalkboardTeacher />, label: 'Photo Albums', url: '/photo-albums' },
//     { icon: <FaGraduationCap />, label: 'Achievements', url: '/achievements' },
//   ];

//   return (
//     <div className="bg-light p-3">
//       <h4>Shortcut Links</h4>
//       <div className="row">
//         {links.map((link, index) => (
//           <div key={index} className="col-3 text-center">
//             <a href={link.url} className="text-decoration-none text-dark">
//               <div className="p-2">
//                 <div className="display-4 text-primary">{link.icon}</div>
//                 <p className="mt-2">{link.label}</p>
//               </div>
//             </a>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Row, Col, Button } from "react-bootstrap";

const ShortcutLinks = () => {
  const links = [
    { icon: "🏛️", label: "Admissions" },  
    { icon: "🖼️", label: "Virtual Tour" },
    { icon: "📚", label: "Library" },
    { icon: "🎨", label: "Art Gallery" },
    { icon: "📄", label: "Publications" },
    { icon: "🎙️", label: "Podcasts" },
    { icon: "📄", label: "Notices And Circulars" },
    { icon: "🎵", label: "Morning Music" },
    { icon: "📷", label: "Photo Albums" },
    { icon: "🏃", label: "Fit India Program" },
    { icon: "📊", label: "Board Results" },
    { icon: "📖", label: "Sanskar Syllabus" },
    { icon: "🧑‍🤝‍🧑", label: "Counselling Corner" },
    { icon: "💳", label: "Online Fee Payment" },
    { icon: "🌍", label: "Citizenship Programme" },
    { icon: "🏫", label: "Summer Internships" },
    { icon: "📜", label: "Mandatory Public Disclosures" },
    { icon: "🎓", label: "Transfer Certificates" },
    { icon: "🤝", label: "SEWA" },
    { icon: "📢", label: "Announcements" },
    { icon: "🖌️", label: "The Theme Of The Year" },
  ];

  return (
    <div>
      <Row>
        {links.map((link, index) => (
          <Col xs={6} sm={4} md={3} className="mb-3" key={index}>
            <Button
              variant="light"
              className="w-100 text-center border shadow-sm p-3"
            >
              <span className="d-block mb-2" style={{ fontSize: "1.5rem" }}>
                {link.icon}
              </span>
              {link.label}
            </Button>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ShortcutLinks;


// export default ShortcutLinks;
