import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Banner({ closeBanner }) {
    return (
        <Modal show={true} onHide={closeBanner} centered>
            <Modal.Header closeButton>
                <Modal.Title>Welcome to Our School!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Take Admission</h3>
                <LinkContainer to="/admission">
                        <Button variant="primary" className="ms-3 fw-bold">Admission Open➔</Button>
                </LinkContainer>
                <p>Important information or announcements can go here.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={closeBanner}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Banner;
