import React from 'react';
// Import the specific Font Awesome icons and the library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    // Handle phone call click
    const handleCallClick = () => {
        window.location.href = "tel:+1234567890"; // Trigger phone call
    };

    // Handle email click
    const handleEmailClick = () => {
        window.location.href = 'mailto:contact@example.com'; // Opens email client
    };

    

    return (
        <div className="container mt-2">
            <div className="text-center mb-4">
                <h1 className="mb-4" style={{ fontSize: '4rem' }}>Contact For Any Query</h1>

                <div className="d-flex align-items-center justify-content-center mb-4">
                    <hr style={{ flex: 1, borderTop: '2px solid #000' }} />
                    <span style={{ padding: '0 10px' }}>NATION FIRST PUBLIC SCHOOL</span>
                    <hr style={{ flex: 1, borderTop: '2px solid #000' }} />
                </div>
            </div>

            {/* Grid layout for contact information */}
            <div className="row">
                {/* Call Button with FontAwesome Phone Icon */}
                <div className="col-12 col-md-4 mb-4">
                    <div className="d-flex align-items-center">
                        <button className="btn btn-success btn-lg" onClick={handleCallClick}>
                            <FontAwesomeIcon icon={faPhone} style={{ fontSize: '1rem', color: 'white' }} />
                        </button>
                        <div className="px-3">
                            <h5 style={{ color: '#0D6EFD' }}>MOBILE</h5>
                            <h5>7439171731 / 7003907752</h5>
                        </div>
                    </div>
                </div>

                {/* Address with Location Icon */}
                <div className="col-12 col-md-5 mb-4">
                    <div className="d-flex align-items-center">
                        <button className="btn btn-success btn-lg">
                            <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '1rem', color: 'white' }} />
                        </button>
                        <div className="px-3" style={{ maxWidth: '800px' }}>
                            <h5 style={{ color: '#0D6EFD' }}>Address</h5>
                            <h5>Mallickpur, Baruipur, Kolkata - 700145</h5>
                        </div>
                    </div>
                </div>

                {/* Email Button with FontAwesome Envelope Icon */}
                <div className="col-12 col-md-3 mb-4">
                    <div className="d-flex align-items-center">
                        <button
                            className="btn btn-success btn-lg"
                            onClick={handleEmailClick}
                        >
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ fontSize: '1rem', color: 'white' }}
                            />
                        </button>
                        <div className="px-3">
                            <h5 style={{ color: '#0D6EFD' }}>EMAIL</h5>
                            <h5>contact@example.com</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-5 mt-3">
                <div className="col-lg-6 col-md-12 wow slideInUp" data-wow-delay="0.3s">
                    <form
                        // action="http://localhost:5000/send_email_contact"
                        method="POST"
                        onsubmit="return validateForm()"
                    >
                        <div className="row g-3">
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control border-0 bg-light px-4"
                                    placeholder="Enter Your Full Name"
                                    style={{ height: 55 }}
                                    required=""
                                    minLength={2}
                                />
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control border-0 bg-light px-4"
                                    placeholder="Enter Your Email"
                                    style={{ height: 55 }}
                                    required=""
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    className="form-control border-0 bg-light px-4"
                                    placeholder="Enter Your Mobile Number"
                                    style={{ height: 55 }}
                                    required=""
                                    pattern="[0-9]{10}"
                                    title="Please enter a valid 10-digit mobile number"
                                />
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="form-control border-0 bg-light px-4 py-3"
                                    id="message"
                                    name="message"
                                    rows={4}
                                    placeholder="Message"
                                    required=""
                                    minLength={10}
                                    defaultValue={""}
                                />
                            </div>
                            <div className="col-12">
                                <button
                                    className="btn btn w-100 py-3"
                                    style={{
                                        color: "white",
                                        backgroundColor: "#498455"
                                    }}
                                    type="submit"
                                    value="Send"
                                >
                                    "SUBMIT YOUR QUOTE"
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* JavaScript for custom validation */}
                <div className="col-lg-6 col-md-12 wow slideInUp" data-wow-delay="0.6s">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3676.1943872079923!2d88.06065569027842!3d22.86927654775837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f865789616a4b1%3A0xb8d8c863e9d9c0e4!2sSunrise%20Model%20Academy!5e0!3m2!1sen!2sin!4v1732774328653!5m2!1sen!2sin"
                        width="100%"
                        height={350}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
            </div>

        </div>
    );
}

export default Contact;
