const ImportantInfo = () => (
    <div>
      <h3>Important Information</h3>
      <section>
        <h4>Medicare Centre</h4>
        <p>
          The school has a fully equipped air-conditioned Medicare centre for first aid. A pediatrician visits daily...
        </p>
      </section>
      <section>
        <h4>Special Education Needs Department</h4>
        <p>
          The Indian School's endeavour for Inclusive Education is the Special Needs Department...
        </p>
        <ul>
          <li>Assessment periodically to see the level of progress.</li>
          <li>Remedial education.</li>
          <li>Developing modified teaching methodology.</li>
        </ul>
      </section>
      <section>
        <h4>Camera View Security Infrastructure</h4>
        <p>
          The school has CCTV cameras in every classroom, labs, and corridors. The surveillance helps ensure safety...
        </p>
      </section>
    </div>
  );
  
  export default ImportantInfo;
  