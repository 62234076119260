import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Banner from "../components/Banner";
import HeroSection from "../components/HeroSection";
import PrincipalMessage from "../components/PrincipalMessage";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
// import Sidebar from '../components/SchoolSidebar';

function Home() {
  // State to control banner visibility
  const [showBanner, setShowBanner] = useState(true);

  // Function to close the banner
  const closeBanner = () => {
    setShowBanner(false);
  };

  return (
    <Container fluid className="home-page m-0">
      {/* Display the Banner component only if showBanner is true */}
      {showBanner && <Banner closeBanner={closeBanner} />}

      {/* Main content of the home page */}
      <div
        className={`main-content ${
          showBanner ? "blurred" : ""
        } text-center py-1 px-0`}
      > 
        <HeroSection />
        <PrincipalMessage />
        <Services />
        <Testimonials />
        <CallToAction />
        <Footer />
      </div>
    </Container>
  );
}

export default Home;
