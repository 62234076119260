import "./App.css";
import React, {useEffect,useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SchoolNavbar from "./components/SchoolNavbar";
import Home from "./pages/Home";
import About from "./pages/About";
import NoticeBoard from "./pages/NoticeBoard";
import Gallery from "./pages/Gallery";
import Requirement from "./pages/Requirement";
import Contact from "./pages/Contact";
import AdminLogin from "./pages/AdminLogin";
import FormAdm from "./components/FormAdm";
import SchoolSidebar from "./components/SchoolSidebar";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 3000); // Simulate loading for 3 seconds
    return () => clearTimeout(timer);
  }, []);
  return (
    <Router>
      <div>
        <SchoolNavbar />
      </div>

      <div
        style={{ display: "flex", flexDirection: "row", minHeight: "100vh" }}
      >
        {/* <div style={{ flex: "0 0 250px", backgroundColor: "#f8f9fa" }}>
          <SchoolSidebar />
        </div> */}
        {/* Main Content */}
        <div style={{ flex: "1", padding: "20px" }}>
          <Routes>
            <Route path="/" element={<FormAdm />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/notice-board" element={<NoticeBoard />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/requirement" element={<Requirement />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admission" element={<FormAdm />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
