import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function PrincipalMessage() {
    return (
        <Container className="my-5">
            <Row>
                <Col md={6}>
                    <Card className="shadow-sm">
                        <Card.Header className="text-primary">From the Principal's Desk</Card.Header>
                        <Card.Body>
                            <p>The word "education" can be best understood...</p>
                            <p><strong>- Razina Khatoon</strong></p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="shadow-sm">
                        <Card.Header className="text-primary">Notice Board</Card.Header>
                        <Card.Body>
                            <p>More details coming soon...</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default PrincipalMessage;
