import { Breadcrumb } from 'react-bootstrap';

const BreadCrumbNav = () => (
  <Breadcrumb>
    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
    <Breadcrumb.Item href="/about">About us</Breadcrumb.Item>
    <Breadcrumb.Item active>Important Information</Breadcrumb.Item>
  </Breadcrumb>
);

export default BreadCrumbNav;
