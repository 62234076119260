import { Nav } from 'react-bootstrap';

const AboutSideBar = () => (
  <Nav defaultActiveKey="/important-info" className="flex-column bg-light p-3" style={{ minHeight: '100vh' }}>
    <Nav.Link href="/about/chairman">Chairman's Message</Nav.Link>
    <Nav.Link href="/about/important-info" active>Important Information</Nav.Link>
    <Nav.Link href="/about/management">Management and Teachers</Nav.Link>
    <Nav.Link href="/about/parents">Our School</Nav.Link>
    <Nav.Link href="/about/vision">Vision and Mission</Nav.Link>
  </Nav>
);

export default AboutSideBar;
