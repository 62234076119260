import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import AboutSideBar from "../components/AboutSideBar";
import BreadCrumbNav from "../components/BreadCrumbNav";
import ImportantInfo from "../components/ImportantInfo";
import Calendar from "../components/Calendar";
import ShortcutLinks from "../components/ShortCutLinks";

// const AboutPage = () => {
//   return (
//     <Container fluid className="about-page">
//       {/* Header Section */}
//       <Row className="text-center bg-light py-5">
//         <Col>
//           <h1>About Podar Education Network</h1>
//           <p>Your pathway to excellence in education.</p>
//         </Col>
//       </Row>

//       {/* Image Section */}
//       <Row className="justify-content-center py-4">
//         <Col md={8}>
//           <img
//             src="path_to_image"
//             alt="Students"
//             className="img-fluid rounded"
//           />
//         </Col>
//       </Row>

//       {/* Description Section */}
//       <Row className="py-4">
//         <Col md={10} className="mx-auto">
//           <p>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
//             vehicula interdum augue, et gravida lacus dignissim sit amet.
//           </p>
//         </Col>
//       </Row>

//       {/* Stats Section */}
//       <Row className="text-center bg-secondary text-white py-4">
//         <Col>
//           <h2>97</h2>
//           <p>Years of Experience</p>
//         </Col>
//         <Col>
//           <h2>149</h2>
//           <p>Institutions</p>
//         </Col>
//         <Col>
//           <h2>2,50,000</h2>
//           <p>Students</p>
//         </Col>
//       </Row>

//       {/* Vision & Purpose */}
//       <Row className="py-4">
//         <Col md={6} className="text-center">
//           <Card className="shadow-sm">
//             <Card.Body>
//               <Card.Title>Our Vision</Card.Title>
//               <Card.Text>
//                 To be the best and trusted education provider for every student.
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6} className="text-center">
//           <Card className="shadow-sm">
//             <Card.Body>
//               <Card.Title>Our Purpose</Card.Title>
//               <Card.Text>
//                 To nurture creative and independent thinkers.
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Timeline Section */}
//       <Row className="text-center bg-light py-4">
//         <Col>
//           <h3>Podar Education Network Timeline</h3>
//           <p>Key milestones and achievements over the years.</p>
//         </Col>
//       </Row>

//       {/* Contact Us Section */}
//       <Row className="bg-secondary text-white py-5">
//         <Col md={4}>
//           <h4>General Inquiries</h4>
//           <p>Email: info@podareducation.org</p>
//         </Col>
//         <Col md={4}>
//           <h4>Media Inquiries</h4>
//           <p>Email: media@podareducation.org</p>
//         </Col>
//         <Col md={4}>
//           <h4>Franchise Opportunities</h4>
//           <p>Email: franchise@podareducation.org</p>
//         </Col>
//       </Row>

//       {/* Footer */}
//       <Row className="text-center bg-dark text-white py-3">
//         <Col>
//           <p>&copy; 2024 Podar Education Network. All rights reserved.</p>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

const AboutPage = () => {
  return (
    <>
      <div className="container mt-4">
        <BreadCrumbNav />
        <div className="row">
          {/* Sidebar */}
          <div className="col-md-3">
            <AboutSideBar />
          </div>

          {/* Main Content */}
          <div className="col-md-6">
            <ImportantInfo />
          </div>
        </div>
        {/* Calendar and Shortcut Links */}
        <Container className="py-4">
      <Row>
        {/* Calendar Section */}
        <Col md={4}>
          <Calendar />
        </Col>

        {/* Shortcut Links Section */}
        <Col md={8}>
          <ShortcutLinks />
        </Col>
      </Row>
    </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AboutPage;
