import React from 'react';
import { Container, Button } from 'react-bootstrap';

function CallToAction() {
    return (
        <Container fluid className="text-center py-5" style={{ backgroundColor: '#e0f7fa' }}>
            <h2>Call To Enroll Your Child</h2>
            <p>Contact us today to learn more about our school</p>
            <Button variant="primary" size="lg">+91 7003907752</Button>
        </Container>
    );
}

export default CallToAction;
